import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {getHomePageStyled} from "./HomePageStyled";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Box, useMediaQuery} from "@material-ui/core";
import VideoPage from "./VideoPage";
import PatentPage from "./PatentPage";
import "@fontsource/poppins";
import HistoryPage from "./HistoryPage";
import ContactPage from "./ContactPage";
import {useTheme} from "@material-ui/core/styles";
import MainPage from "./MainPage";
import AboutPage from "./AboutPage";
import SolutionPage from "./SolutionPage";
import NewsPage from "./NewsPage";
import ContactForm from "./ContactForm";
import MailForm from "./MailForm";
import NoticePage from "./NoticePage";
import SecurityPolicyForm from "./SecurityPolicyForm";
import PrivacyPolicyPage from "./PrivacyPolicyPage"
import PrivacyPolicyForm from "./PrivacyPolicyForm";
import Modal from "./MainPagePopUp";


function _subDotTitle(props) {
    const {title, number, onClickHandler, visible} = props;

    return (
        <li className={`${visible ? 'active' : '' }`}
            style={{marginBottom:8}}
            onClick={() => onClickHandler(number) }>
            {title}
            <Box><Box/></Box>
        </li>
    )
}

function _subDotItem(props) {
    const {title, number, sliderIdx, onClickHandler, clazz} = props;

    return (
        <li className={`${number === sliderIdx ? 'active' : '' }`}
            style={clazz}
            onClick={() => onClickHandler(number) }>
            {title}
        </li>
    )
}

function _dotItem(props) {
    const {title, number, sliderIdx, onClickHandler, clazz} = props;

    return (
        <li className={`${number === sliderIdx ? 'active' : '' }`}
            style={clazz}
            onClick={() => onClickHandler(number) }>
            {title}
            <Box><Box/></Box>
        </li>
    )
}

export default function Homepage({forwardRef, loginPanelHandler}){
    const style = getHomePageStyled();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
    const outerRef = useRef();
    const sliderRef = useRef();
    const [sliderIdx, setSliderIdx] = useState(0);
    const [visible, setVisible] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {

        const outerRefCurrent = outerRef.current;

        const wheelHandler = (e) => {
            e.preventDefault();
            const {deltaY} = e;

            if(deltaY >= 0 ) {
                sliderRef.current?.slickNext()
            } else {
                sliderRef.current?.slickPrev();
            }

        }

        outerRefCurrent?.addEventListener("wheel", wheelHandler);

        const mouseHandler = () => {
            loginPanelHandler(false);
        }

        outerRefCurrent?.addEventListener("mousedown", mouseHandler);

        return () => {
            outerRefCurrent?.removeEventListener("wheel", wheelHandler);
            outerRefCurrent?.removeEventListener("mousedown", mouseHandler);
        }

    },[]);
    const slickSliderGoTo = (value) => {
        if( !disabled ) {
            if (isPhone && value === 7) {
                sliderRef.current?.slickGoTo(8);
            } else {
                sliderRef.current?.slickGoTo(value);
            }
            setSliderIdx(value);
        }
    }

    const onAfterChange = ( index ) => {
        if (isPhone && index === 7) {
            setSliderIdx( 6 );
        } else {
            setSliderIdx( index );
        }
        setDisabled( false );
    }


    const onBeforeChange = (current, next) => {
        if( 4 < next && next < 10 ){
            setVisible(true);
        } else {
            setVisible(false);
        }
        setDisabled(true);
        loginPanelHandler(false);
    }

    const settings = {
        dots: false,
        arrows:false,
        infinite: false,
        lazyLoad:true,
        draggable:false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange : onAfterChange,
        beforeChange: onBeforeChange
    };

    const _subDotClass = {
        fontSize:12,
        fontWeight:500,
        lineHeight:'18px',
        justifyContent:'flex-end',
        marginBottom:0,
        display: visible ? 'flex' : 'none'
    }

    const [modalVisible, setModalVisible] = useState(true)
    const closeModal = () => {
        setModalVisible(false)
    }

    useImperativeHandle(forwardRef , () => ({
        sliderIdx: sliderIdx
        ,slickSliderGoTo: slickSliderGoTo
    }));

    return (
        <Box ref={outerRef} className={style.root}>

            {modalVisible && (
                <Modal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal}></Modal>
            )}

            <Slider ref={sliderRef} {...settings} className={style.homeSlider}>
                <MainPage />
                <AboutPage />
                <SolutionPage />
                <NewsPage />
                <VideoPage />
                <PatentPage />
                <HistoryPage index={1}/>
                {isPhone && <HistoryPage index={2} />}
                <NoticePage />
                {!isMobile && <PrivacyPolicyPage />}
                {isMobile && <SecurityPolicyForm />}
                {isMobile && <PrivacyPolicyForm /> }
                {!isMobile && <ContactPage /> }
                {isMobile && <ContactForm /> }
                {isMobile && <MailForm /> }
            </Slider>

            {
                !isMobile &&
                <Box className={style.dots}>
                    <ul>
                        <_dotItem title={"MAIN"} number={0} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} />
                        <_dotItem title={"ABOUT"} number={1} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} />
                        <_dotItem title={"SOLUTION"} number={2} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} />
                        <_dotItem title={"NEWS"} number={3} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} />
                        <_dotItem title={"VIDEO"} number={4} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} />
                        <_subDotTitle title={"COMPANY"} number={5} visible={visible} onClickHandler={slickSliderGoTo} />
                        <_subDotItem title={"Patent"} number={5} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} clazz={_subDotClass} />
                        <_subDotItem title={"History"} number={6} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} clazz={_subDotClass} />
                        <_subDotItem title={"Notice"} number={7} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} clazz={_subDotClass} />
                        <_subDotItem title={"PrivacyPolicy"} number={8} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} clazz={_subDotClass} />
                        <_subDotItem title={"Contact US"} number={9} sliderIdx={sliderIdx} onClickHandler={slickSliderGoTo} clazz={_subDotClass} />
                    </ul>
                </Box>
            }
        </Box>
    )
}